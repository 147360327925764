.press-container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #EEE;
    text-align: center;
}

/* Media Queries */
@media (max-width: 1024px) {
    .press-container {
        font-size: 1rem;
    }
}
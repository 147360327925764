.copyright {
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    margin: auto;
    position: fixed;
    bottom: 20px;
    color: #EEE;
    z-index: 100;
}

.copyright-link {
    color: #fb5c00;
}